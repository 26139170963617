<div class="grid-fluid">
  <div class="footer-block" *ngFor="let node of nodes">
    <div class="footer-block-heading">{{ node.titleCn ?? node.title }}</div>
    <ul>
      <li *ngFor="let item of node.children">
        <a class="link" [href]="item.url"
           [title]="item.tooltipCn ?? item.tooltip ?? item.titleCn ?? item.title">{{ item.titleCn ?? item.title }}</a>
      </li>
    </ul>
  </div>
</div>

<p>
  Super-powered by Google ©2010-{{ currentYear }}.
</p>
<p>
  代码授权方式：<a href="license" title="License text">MIT-style License</a>.
  文档授权方式：<a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>.
</p>
<p>
  当前版本：{{versionInfo?.full}}.
</p>
<p>
  <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备13038357号-21</a>
</p>
